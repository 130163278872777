<div class="font-sans overflow-hidden flex flex-col sm:rounded-lg sm:shadow h-full">
  <div class="bg-white px-5 py-5 border-b border-gray-200 sm:pr-0">
    <div class="-ml-4 -mt-4 flex flex-col sm:flex-row justify-between sm:items-center flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-4 text-2xl leading-6 font-medium text-gray-900 flex-1">
        Quotes
      </div>
      <div class="ml-4 mt-4 flex flex-col sm:flex-row sm:flex-grow-0 gap-2 flex-grow mr-4">
        <div class="flex flex-grow">
          <mat-form-field class="flex flex-grow">
            <mat-label>Search Quotes</mat-label>
            <input matInput [(ngModel)]="searchTerm" #search>
            <mat-icon matSuffix class="cursor-pointer" (click)="searchData()">search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div [class.hidden]="totalRecords <= 0" class="flex flex-grow bg-white overflow-auto">
    <table mat-table [dataSource]="data" matSort [trackBy]="trackShipmentQuote" [matSortActive]="savedState.sortColumn" [matSortStart]="savedState.sortDirection">
      @for (column of selectedColumns; track column) {
        <ng-container [matColumnDef]="column.columnName">
          @if (column.columnName == 'delete') {
            <th mat-header-cell *matHeaderCellDef style="width: 45px;">&nbsp;</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;">
              <i class="far fa-trash-alt" (click)="cancelShipment(row.id)"></i>
            </td>
          }
          @if (column.columnName != 'delete') {
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{column.label}}</th>
            <td mat-cell *matCellDef="let element">
              @if (column.columnName == 'id') {
                <a class="link" [routerLink]="['/shipments/quotes/', element.id]">{{element.id}}</a>
              }
              @if (column.columnName != 'id') {
                <sn-grid-column [column]="column" [element]="element"></sn-grid-column>
              }
            </td>
          }
        </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="selectedColumnsString; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: selectedColumnsString;"></tr>
    </table>
  </div>
  <mat-paginator [class.hidden]="totalRecords <= 0" class="flex justify-self-start bg-gray-50" #matPaginator [pageIndex]="savedState.page" [pageSize]="savedState.pageSize" [pageSizeOptions]="[25, 50, 100, 250]" [length]="totalRecords" [showFirstLastButtons]="true"></mat-paginator>
  <div [class.hidden]="totalRecords == -1 || totalRecords > 0" class="h-full flex items-center justify-center bg-white">
    <sn-material-grid-empty-table
      [tableName]="'Quotes'"
      [addButtonText]="'Quote'"
      [showAddButton]="true"
      (addData)="newShipmentQuote()"
      >
    </sn-material-grid-empty-table>
  </div>
</div>
<div class="fab flex flex-col gap-4">
  @if (isASMUser) {
    <a mat-fab color="amber" aria-label="New Quick Quote" routerLink="/shipments/quotes/quick" style="z-index: 1000;" matTooltip="Generate Quick Quote">
      <mat-icon>bolt</mat-icon>
    </a>
  }
  <a mat-fab color="primary" aria-label="New Shipment Quote" routerLink="/shipments/new" style="z-index: 1000;" matTooltip="Generate Shipment Quote">
    <mat-icon>add</mat-icon>
  </a>
</div>
